import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar } from '@angular/material/snack-bar';

import moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { take } from 'rxjs/operators';

import { ConstItem } from 'src/app/services/constants.service';
import { JobService } from 'src/app/services/job.service';
import { ValidationAtLeastOneFieldService } from 'src/app/services/validation-at-least-one-field.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../../models/abbreviation.model';
import { ApplicantService } from '../../../services/applicant.service';

const codes = require('german-postal-codes');

@Component({
  selector: 'app-applier-sidebar',
  templateUrl: './applier-sidebar.component.html',
  styleUrls: ['./applier-sidebar.component.scss'],
})
export class ApplierSidebarComponent implements OnInit {
  @Input() applierId: any;
  @Input() editMode: boolean = true;
  @Output() updateAndClose = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();
  @Output() doClose = new EventEmitter<any>();
  @Output() applierDataEvent = new EventEmitter<any>();
  @Output() handleError = new EventEmitter<any>();
  @ViewChild('drawer') drawer: MatSidenav;

  applicantForm: FormGroup;
  dropdownSettings: IDropdownSettings;
  dropDownSingleSelect: IDropdownSettings;
  multiSelectWithSearch: IDropdownSettings;
  singleSelectWithSearch: IDropdownSettings;
  selectedPosition: ConstItem[] = [];
  selectedLanguageSkill: ConstItem[] = [];
  selectedWorkExperience: ConstItem[] = [];
  selectedAreaOfExperience: ConstItem[] = [];
  selectedEducationItems = [];
  selectedQuestionItems = [];
  subscribedJobNewsletterChannels = [];
  selectedCareTypeItems = [];
  selectedVaccinationTypeList = [];
  selectedDrivingLicanse = [];
  selectedShiftHoursItems = [];
  selectedAgent = [];
  selectedContinuingEducation = [];
  jobNewsletterChannelList = [];
  selectedScopeItems = [];
  dropdownAgents = [];
  files = [];
  extID = '';
  hideOther = false;
  applierData: any;
  showOtherRef = false;
  isEduOtherShow: boolean = false;
  isPostcodeValid = false;
  isShiftHoursItem = false;
  isShiftOtherItem = false;
  isContiEduShow = false;
  ShiftOthersItems = '';
  jobId: number;
  companyId: number;
  applicantUrl = '';

  constructor(
    private applicantService: ApplicantService,
    private jobService: JobService,
    private fb: FormBuilder,
    public constants: Constants,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initApplicantForm();
    this.getAgentList();
    this.getApplierDetails();
    this.getJobNewsletterChannels();
  }

  initApplicantForm() {
    this.applicantForm = this.fb.group(
      {
        id: [],
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        email: ['', Validators.required],
        birth_date: [],
        contacts: this.fb.array([this.defaultContact()]),
        date: [],
        education: [''],
        care_type: ['', Validators.required],
        shift_hour: [],
        scope: ['', Validators.required],
        postcode: ['', Validators.required],
        start_date: ['', Validators.required],
        position: [],
        position_other: [],
        last_2_employers: [],
        shift_other: [],
        reference_by: [],
        others: [],
        extID: [],
        agent_user: [],
        language_skills: [],
        work_experience: [],
        continuing_education: [],
        continous_education_other: [],
        driving_license: [],
        education_other: [],
        vaccination_status: ['', Validators.required],
        vaccination_date: [],
        note: [],
        motivation: [],
        campaign_keyword: [],
      },
      {
        validators: [
          ValidationAtLeastOneFieldService(Validators.required, [
            'shift_other',
            'shift_hour',
          ]),
        ],
      }
    );
  }

  get selectedContact() {
    return this.applicantForm.get('contacts') as FormArray;
  }

  defaultContact() {
    return this.fb.group({
      id: null,
      contact_type: ['', Validators.required],
      contact: [null, Validators.required],
      is_verified: false,
      superchat_url: '',
    });
  }

  addContact() {
    this.selectedContact.push(this.defaultContact());
  }

  deleteContact(index: number) {
    this.selectedContact.removeAt(index);

    if (this.selectedContact.length === 0) {
      this.addContact();
    }
  }

  patchApplierContact(applier) {
    const contacts = applier?.user?.contacts ?? [];
    if (!contacts.length) {
      this.resetContacts();
      return;
    }
    this.selectedContact.removeAt(0);
    contacts.map((contact, index, contacts) => {
      let superchat_url = this.superchatApplicantUrl(contact);

      return this.selectedContact.push(
        this.fb.group({
          id: contact?.id,
          contact_type: contact.contact_type,
          contact: contact.contact,
          is_verified: Boolean(contact?.verified_at),
          superchat_url: superchat_url,
        })
      );
    });
  }

  resetContacts() {
    this.selectedContact.clear();
    this.addContact();
  }

  getApplierDetails() {
    this.jobService.getApplierDetails(this.applierId).subscribe((res: any) => {
      this.applierData = res;
      this.jobId = res.jobId;
      this.companyId = res.company_id;
      this.applierDataEvent.emit(res);

      if (
        this.applierData.postcode !== 'undefined' &&
        this.applierData.postcode
      ) {
        const target = codes.find(temp => temp == this.applierData.postcode);
        if (target) this.isPostcodeValid = false;
        else this.isPostcodeValid = true;
      }

      if (this.applierData.shift_hour.length) {
        this.isShiftHoursItem = false;
      } else {
        this.isShiftHoursItem = true;
      }

      if (!this.applierData.driving_license) {
        this.applierData.driving_license = 1;
      }

      if (this.applierData.shift_other) {
        this.isShiftOtherItem = false;
      } else {
        this.isShiftOtherItem = true;
      }
      this.ShiftOthersItems = this.applierData.shift_other;

      const unsubscribedJobNewsletterChannels =
        this.applierData.message_unsubscriptions.map(
          message_unsubscription => message_unsubscription.message_channel
        );
      this.subscribedJobNewsletterChannels =
        this.jobNewsletterChannelList.filter(
          jobNewsletterChannel =>
            !unsubscribedJobNewsletterChannels.includes(jobNewsletterChannel.id)
        );

      this.patchForm();
      this.patchApplierContact(this.applierData);
      this.inItSelect2List(this.applierData);
    });
  }

  patchForm() {
    this.applicantForm.patchValue({
      email: this.applierData?.user?.email,
      first_name: this.applierData?.user?.first_name,
      last_name: this.applierData?.user?.last_name,
      birth_date: this.applierData?.user?.birth_date,
      id: this.applierData.id,
      date: this.applierData.date,
      education: this.applierData.education,
      care_type: this.applierData.care_type,
      shift_hour: this.applierData.shift_hour,
      scope: this.applierData.scope,
      postcode: this.applierData.postcode,
      start_date: this.applierData.start_work_date,
      last_2_employers: this.applierData.last_2_employers,
      others: this.applierData.others,
      shift_other: this.applierData.shift_other,
      reference_by: this.applierData.reference_by,
      education_other: this.applierData.education_other,
      vaccination_status: this.applierData.vaccination_status,
      vaccination_date: this.applierData.vaccination_date,
      driving_license: this.applierData.driving_license,
      continous_education_other: this.applierData.continuing_education_other,
      note: this.applierData?.note,
      motivation: this.applierData?.motivation,
      position_other: this.applierData?.position_other,
      campaign_keyword: this.applierData?.campaign_keyword,
    });

    this.extID = this.applierData.extID;
    this.files = this.applierData?.user?.files;
    this.applicantUrl = `${environment.laenkApps.applicant.url}/${this.applierData.extID}`;
  }

  inItSelect2List(rowData) {
    const baseDropDownSettigs = {
      singleSelection: true,
      idField: 'id',
      textField: 'label',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      searchPlaceholderText: 'Suche',
      itemsShowLimit: 6,
      allowSearchFilter: false,
      enableCheckAll: false,
    };

    this.dropdownSettings = {
      ...baseDropDownSettigs,
      singleSelection: false,
    };

    this.dropDownSingleSelect = {
      ...baseDropDownSettigs,
    };

    this.singleSelectWithSearch = {
      ...baseDropDownSettigs,
      allowSearchFilter: true,
    };
    this.multiSelectWithSearch = {
      ...this.dropdownSettings,
      allowSearchFilter: true,
    };

    if (rowData?.agent_user) {
      this.selectedAgent = [
        {
          id: rowData.agent_user.id,
          label: `${rowData.agent_user?.first_name} ${rowData.agent_user?.last_name}`,
        },
      ];
    }

    this.setSelectedPosition(rowData?.position);

    if (rowData.education) {
      this.constants
        .getList('education_type')
        .pipe(take(1))
        .subscribe(choices => {
          (rowData.education as number[]).forEach((education: number) => {
            const selectedEducation = choices.find(
              (e: ConstItem) => e.id === education
            );
            this.selectedEducationItems.push(selectedEducation);
          });
        });
    }

    if (rowData?.language_skill) {
      this.constants
        .getList('language_skill')
        .pipe(take(1))
        .subscribe(languages => {
          const languageSkill = languages.find(
            (lang: ConstItem) => lang.id === rowData.language_skill
          );
          this.selectedLanguageSkill.push(languageSkill);
        });
    }

    if (rowData?.work_experience) {
      this.constants
        .getList('work_experience')
        .pipe(take(1))
        .subscribe(experiences => {
          const workExperience = experiences.find(
            (experience: ConstItem) => experience.id === rowData.work_experience
          );
          if (workExperience) this.selectedWorkExperience.push(workExperience);
        });
    }

    if (rowData?.area_of_experience?.length) {
      this.constants
        .getList('area_of_experience')
        .pipe(take(1))
        .subscribe(experiences => {
          rowData?.area_of_experience?.forEach(experience => {
            const areaOfExperience = experiences.find(
              (exp: ConstItem) => exp.id === experience
            );
            if (areaOfExperience)
              this.selectedAreaOfExperience.push(areaOfExperience);
          });
        });
    }

    if (rowData?.vaccination_status) {
      this.constants
        .getList('vaccination_status_type')
        .pipe(take(1))
        .subscribe(vaccination_status => {
          const vaccinationStatus = vaccination_status.find(
            (status: ConstItem) => status.id === rowData.vaccination_status
          );
          if (vaccinationStatus)
            this.selectedDrivingLicanse.push(vaccinationStatus);
        });
    }

    if (rowData?.driving_license) {
      this.constants
        .getList('driver_license_type')
        .pipe(take(1))
        .subscribe(licenses => {
          const drivingLicense = licenses.find(
            (license: ConstItem) => license.id === rowData.driving_license
          );
          if (drivingLicense) this.selectedDrivingLicanse.push(drivingLicense);
        });
    }

    if (rowData?.care_type) {
      this.constants
        .getList('care_type')
        .pipe(take(1))
        .subscribe(care_types => {
          rowData?.care_type?.forEach(care_type => {
            const careType = care_types.find(
              (c: ConstItem) => c.id === care_type
            );
            if (careType) this.selectedCareTypeItems.push(careType);
          });
        });
    }

    if (rowData?.shift_hour?.length) {
      this.constants
        .getList('shift_hour_type')
        .pipe(take(1))
        .subscribe(hours => {
          rowData?.shift_hour?.forEach(hour => {
            const shift_hour = hours.find((h: ConstItem) => h.id === hour);
            if (shift_hour) this.selectedShiftHoursItems.push(shift_hour);
          });
        });
    }

    if (rowData?.working_type?.length) {
      this.constants
        .getList('shift_time_type')
        .pipe(take(1))
        .subscribe(times => {
          rowData?.working_type?.forEach(time => {
            const working_type = times.find((t: ConstItem) => t.id === time);
            if (working_type) this.selectedScopeItems.push(working_type);
          });
        });
    }

    if (rowData?.continuing_education) {
      this.constants
        .getList('continuing_education_type')
        .pipe(take(1))
        .subscribe(ce => {
          rowData?.continuing_education?.forEach(ce_type => {
            const ceType = ce.find((c: ConstItem) => c.id === ce_type);
            this.selectedContinuingEducation.push(ceType);
          });
        });

      if (
        rowData.continuing_education.includes(15) ||
        rowData.continuing_education_other
      ) {
        this.isContiEduShow = true;
      }
    }
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  setSelectedPosition(positions: number[]) {
    this.constants
      .getList('position')
      .pipe(take(1))
      .subscribe(values => {
        const selectedPosition = (positions || []).map(position => {
          const item = values.find((val: ConstItem) => val?.id === position);
          if (item) return item;
        });
        this.selectedPosition = selectedPosition;
      });
  }

  checkShiftHour() {
    if (this.selectedShiftHoursItems.length)
      return (this.isShiftHoursItem = false);
    this.isShiftHoursItem = true;
  }

  checkShiftOthers(e: any) {
    if (e) return (this.isShiftOtherItem = false);
    this.isShiftOtherItem = true;
  }

  getAgentList() {
    this.jobService.getAgent(true).subscribe((res: any) => {
      this.dropdownAgents = (res || []).map(agent => ({
        id: agent.id,
        label: agent.first_name + ' ' + agent.last_name,
      }));
    });
  }

  getJobNewsletterChannels() {
    this.constants
      .getList('message_channel')
      .pipe(take(1))
      .subscribe(values => {
        this.jobNewsletterChannelList = values;
      });
  }

  checkEducationList() {
    this.isEduOtherShow = false;
    this.applicantForm.controls['education_other'].clearValidators();
    for (let education of this.selectedEducationItems) {
      if (education.id == 7) {
        this.applicantForm.controls['education_other'].setValidators([
          Validators.required,
        ]);
        this.isEduOtherShow = true;
      }

      this.applicantForm.controls['education_other'].updateValueAndValidity();
    }
  }

  checkContiEducationList() {
    this.isContiEduShow = false;
    for (let education of this.selectedContinuingEducation) {
      if (education.id == 15) {
        this.isContiEduShow = true;
      }
    }
  }

  checkPostCode(event: any) {
    if (
      this.applicantForm.value.postcode !== 'undefined' &&
      this.applicantForm.value.postcode
    ) {
      var target = codes.find(
        temp => temp == this.applicantForm.value.postcode
      );
      if (target) this.isPostcodeValid = false;
      else this.isPostcodeValid = true;
    }
  }

  updateData(isClose = false) {
    const id = this.applicantForm.value.id;

    const birt_date = this.applicantForm?.value?.birth_date;

    const continuingEducation =
      this.applicantForm.value.continuing_education.map(ce => ce.id);

    const care_type = this.selectedCareTypeItems.map(care => care.id);

    const position = this.selectedPosition.map(position => position.id);

    const shift_hour = this.selectedShiftHoursItems.map(shift => shift.id);

    const working_type = this.selectedScopeItems.map(work => work.id);

    const language_skill = this.selectedLanguageSkill.length
      ? this.selectedLanguageSkill[0].id
      : null;

    const work_experience = this.selectedWorkExperience.length
      ? this.selectedWorkExperience[0].id
      : null;

    const area_of_experience = this.selectedAreaOfExperience.map(i => i.id);

    const education = this.selectedEducationItems.map(
      education => education.id
    );

    const subscribedJobNewsletterChannelIds =
      this.subscribedJobNewsletterChannels.map(newsletter => newsletter.id);
    const unsubcribed_job_newsletter_channels =
      this.jobNewsletterChannelList.filter(
        jobNewsletterChannel =>
          !subscribedJobNewsletterChannelIds.includes(jobNewsletterChannel.id)
      );

    let dt;
    if (typeof this.applicantForm.value.start_date == 'object') {
      const newdate = new Date(this.applicantForm.value.start_date);
      dt =
        newdate.getFullYear() +
        '-' +
        ((newdate || new Date()).getMonth() + 1) +
        '-' +
        newdate.getDate();
    } else {
      dt = this.applicantForm.value.start_date;
    }

    let vacc_dt;
    if (typeof this.applicantForm.value.vaccination_date == 'object') {
      const newdate = new Date(this.applicantForm.value.vaccination_date);
      vacc_dt =
        newdate.getFullYear() +
        '-' +
        ((newdate || new Date()).getMonth() + 1) +
        '-' +
        newdate.getDate();
    } else {
      vacc_dt = this.applicantForm.value.vaccination_date;
    }

    const data = {
      id,
      education,
      unsubcribed_job_newsletter_channels,
      language_skill,
      care_type,
      shift_hour,
      working_type,
      work_experience,
      area_of_experience,
      start_work_date: dt,
      position,
      position_other: this.applicantForm.value.position_other,
      first_name: this.applicantForm.value.first_name,
      last_name: this.applicantForm.value.last_name,
      birth_date: birt_date ? moment(birt_date).format('YYYY-MM-DD') : null,
      contacts: this.applicantForm.value.contacts,
      postcode: this.applicantForm.value.postcode,
      email: this.applicantForm.value.email,
      last_2_employers: this.applicantForm?.value?.last_2_employers?.trim(),
      others: this.showOtherRef === true ? this.applicantForm.value.others : '',
      shift_other: this.applicantForm.value.shift_other,
      note: this.applicantForm.value.note,
      motivation: this.applicantForm.value.motivation,
      driving_license: this.applicantForm?.value?.driving_license ?? null,
      agent_user:
        this.applicantForm.value.agent_user?.length > 0
          ? this.applicantForm.value.agent_user[0]?.id
          : null,
      continuing_education: continuingEducation,
      continuing_education_other:
        !this.isContiEduShow ||
        this.applicantForm.value.continous_education_other === ''
          ? null
          : this.applicantForm.value.continous_education_other,
      education_other:
        !this.isEduOtherShow || this.applicantForm.value.education_other === ''
          ? null
          : this.applicantForm.value.education_other,
      vaccination_status: this.applicantForm.value.vaccination_status
        ? this.applicantForm.value.vaccination_status
        : null,
      vaccination_date:
        this.applicantForm.value.vaccination_status == 3 ? vacc_dt : null,
    };

    this.applicantService.updateApplierDetails(id, data).subscribe(
      (response: any) => {
        if (isClose) {
          this.updateAndClose.emit(response);
        } else {
          this.setSelectedPosition(response.position);
          this.resetContacts();
          this.patchApplierContact(response);
          this.update.emit(response);
        }
      },
      error => {
        this.handleError.emit();
      }
    );
  }

  isPhoneNumberValid(phone_number: string): boolean {
    if (phone_number.length < 8) return false;
    if (new Set(phone_number).size < 3) return false;
    if (!phone_number.startsWith('+')) return false;
    return true;
  }

  superchatApplicantUrl(contact) {
    const { first_name = '', last_name = '' } = this.applierData?.user;
    const phone_number = contact?.contact;
    if (!phone_number) return;
    if (this.isPhoneNumberValid(phone_number))
      return `https://app.superchat.de/inbox/find/?wa=${phone_number}&firstname=${first_name}&lastname=${last_name}`;
  }

  close() {
    this.doClose.emit();
  }

  uploadFile(event) {
    const formData = new FormData();
    formData.append('user_file', event?.target?.files?.[0]);
    this.applicantService
      .uploadUserFile(this.applierData?.user?.external_id, formData)
      .subscribe(response => {
        this.files.push(response);
        this._snackBar.open('File Uploaded!', 'OK', { duration: 5000 });
      });
  }

  deleteFile(file: any) {
    this.applicantService
      .deleteUserFile(this.applierData?.user?.external_id, file?.id)
      .subscribe(
        response => {
          const index = this.files.findIndex(f => f.id == file.id);
          this.files[index].is_removed = true;

          this._snackBar.open('File Deleted!', 'OK', { duration: 5000 });
        },
        error => {
          this._snackBar.open(error, 'OK', { duration: 5000 });
        }
      );
  }

  updateFile(file, { event = null, restoreFile = false }) {
    const payload = {};
    if (event) payload['type'] = event?.target?.value;
    if (restoreFile) payload['is_removed'] = false;
    this.applicantService
      .restoreUserFiles(this.applierData?.user?.external_id, file?.id, {
        ...payload,
      })
      .subscribe(
        response => {
          if (restoreFile) {
            const index = this.files.findIndex(f => f.id == file.id);
            this.files[index].is_removed = false;
          }
          const message = restoreFile ? 'File Restored!' : 'File Updated';

          this._snackBar.open(message, 'OK', { duration: 5000 });
        },
        error => {
          this._snackBar.open(error, 'OK', { duration: 5000 });
        }
      );
    console.log(event?.target?.value, file);
  }
}
